import React from "react";
import { Col, Container, Row, Button } from "react-bootstrap";

import Modal from "../bootstrap/modal";

export default function TermModal(props) {
  const { onClose } = props;

  return (
    <Modal
      title={"Privacy Policy"}
      onClose={onClose}
      show
      footer={
        <Row>
          <Col xs="auto" className="ml-auto"></Col>
          <Col xs="auto">
            <Button variant="darkblue" type="button" onClick={() => onClose()}>
              {"Close"}
            </Button>
          </Col>
        </Row>
      }
    >
      <Container fluid>
        <Row>
          <Col>
            <h2>
              <strong>Privacy</strong>
            </h2>
            <p>
              Evergy is committed to protecting your privacy and ensuring that
              your personal information is responsibly collected and managed.
            </p>
            <p>
              All personal information submitted by you to Evergy in connection
              with your use of the Service will be subject to the&nbsp;
              <a href="">Evergy Privacy Policy</a> and managed in accordance with
              the&nbsp;
              <a href="https://www.legislation.qld.gov.au/view/html/inforce/current/act-2009-014">
                <i>Information Privacy Act 2009&nbsp;</i>(Qld)
              </a>
              .
            </p>
            <p>
              Evergy is not responsible for the use that any third party may make
              of personal information obtained through your use of the
              Service.&nbsp; It is your responsibility to ensure that websites
              accessed by you have appropriate policies for the handling of
              personal information.&nbsp;
            </p>
            <p>&nbsp;</p>
            <h2>Collection of personal information</h2>
            <p>
              Evergy collects the following personal information about Users for
              the purpose of providing access to&nbsp;
            </p>
            <p>the Service:&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</p>
            <ul>
              <li>your device's Media Access Control address (MAC address)</li>
              <li>
                the addresses of the websites that you visit while you are
                connected to Evergy's Public Wi-Fi (IP addresses)
              </li>
              <li>
                Name of federated service (being log-in details used to access
                different system e.g. Gmail, Yahoo etc.), or
              </li>
              <li>Name, email (optional), phone number (optional)</li>
              <li>
                Acceptance of these Terms and Conditions (including
                parental/guardian consent where applicable); and
              </li>
              <li>
                &nbsp;Your browser history for the purpose of ensuring use of
                the Service complies with these Terms and Use.
              </li>
            </ul>
            <p>
              <i>&nbsp;</i>
            </p>
            <h2>Disclosure, Publication and Enquiries&nbsp;</h2>
            <p>
              Evergy will not disclose to any external persons or entities
              personal information it collects when you use the Service, unless
              required by law.
            </p>
            <p>
              If you have any questions about Evergy's privacy and information
              security practices, you can contact the&nbsp;
              <a href="">Evergy Privacy Officer</a>.
            </p>
          </Col>
        </Row>
      </Container>
    </Modal>
  );
}
